<mat-card class="mat-card">
  <mat-card-header>
    <mat-card-title>{{rowTable.note}}</mat-card-title>
    <mat-card-subtitle>{{rowTable.origin}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="container-sides">
    <div class="left-side">
      <h5>Límite de preguntas por alumno: {{rowTable.max_questions}}</h5>
      <h5>Límite presupuesto total: {{rowTable.budget_limit}} $</h5>
    <div class="limit-access">
      <h5>Limitar acceso al alcanzar presupuesto: </h5>
      <h5 *ngIf="rowTable.budget_block">
         Si
      </h5>
        <h5 *ngIf="!rowTable.budget_block">
        No
        </h5>
    </div>
    </div>
    <div class="right-side">
      <h5>Total de alumnos que han usado el servicio: {{rowTable.total_users}} </h5>
      <h5>Total consultas realizadas: {{rowTable.questions_made}}</h5>
      <h5>Presupuesto total gastado: {{rowTable.total_money_spent}} $</h5>
    </div>

  </div>
  </mat-card-content>
  <mat-card-actions align="end">
      <button [disabled]="rowTable.disabled" mat-fab color="primary" class="edit-button" (click)="updateRow(rowTable)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-fab color="basic" class="disable-button" (click)="disableRow(rowTable)">
        <mat-icon>block</mat-icon>
      </button>
    <button [disabled]="rowTable.disabled" mat-fab color="warn" class="delete-button" (click)="deleteRow(rowTable)">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
