import { Component } from '@angular/core';
import { TableRepositoryService } from 'src/app/shared/services/repository/table-repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RowTable } from 'src/app/shared/domain/interfaces/row-table';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-delete-row',
  templateUrl: './delete-row.component.html',
  styleUrls: ['./delete-row.component.scss']
})
export class DeleteRowComponent {

  constructor(private tableRepository: TableRepositoryService,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    public dialogActions: MatDialogRef<DeleteRowComponent>,

    @Inject(MAT_DIALOG_DATA) public rowToDelete: RowTable,
  ) {



  }
  public deleteRow() {
    this.tableRepository.deleteRow(this.rowToDelete.id).subscribe(() => {
      this.snackBar.open('Dominio eliminado', undefined, { duration: 1000 });
    });
    this.dialogActions.close();
  }

  closeDialog(): void {
    this.dialogActions.close(false);
  }
}
