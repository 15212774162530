<div class="table-container">
<div class="table-header">
  <!-- <app-filter ></app-filter> -->
  <button
  mat-fab
  color="warn"
  class="search-button"
  (click)="addRow()"
>
  <mat-icon>add</mat-icon>
</button>
</div>

<app-row-table *ngFor="let rowTable of rows" [rowTable]="rowTable"></app-row-table>

</div>
