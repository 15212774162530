import { Component, OnInit } from '@angular/core';
import { RowTable } from 'src/app/shared/domain/interfaces/row-table';
import { TableRepositoryService } from 'src/app/shared/services/repository/table-repository.service';
import { AddRowComponent } from '../add-row/add-row.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit{

  constructor(private tableRepository: TableRepositoryService,private dialog: MatDialog,){}
  ngOnInit(): void {

    this.getAllRows();


  }
  public rows : Array<RowTable> = [];

  getAllRows(){
    this.tableRepository.getAllRows().subscribe((rows: any)=> {


        this.rows = rows.results.map((row : RowTable) => ({
          id : row.id,
          origin: row.origin,
          note : row.note,
          max_questions: row.max_questions,
          budget_limit: row.budget_limit,
          budget_block: row.budget_block,
          disabled: row.disabled,
          questions_made: row.questions_made,
          total_money_spent: Math.round(row.total_money_spent * 10000) / 10000,
          total_users: row.total_users

        }))


    })
  }

  addRow() {
    let dialogRef = this.dialog.open(AddRowComponent, {
      width: '70%',
      height: '90%',
      data: AddRowComponent
    });
    dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    });
  }
}
