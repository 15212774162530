import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/components/login/login.component';
import { TableComponent } from './modules/components/table/table.component';
import { MainLayoutComponent } from './modules/components/main-layout/main-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';


const routes: Routes = [
  { path: '', pathMatch:'full', redirectTo: 'login'},
  { path: 'login', component: LoginComponent },
  { path: 'table', component: MainLayoutComponent , canActivate:[AuthGuard]}
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
