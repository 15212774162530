import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TableRepositoryService } from 'src/app/shared/services/repository/table-repository.service';
import { DeleteRowComponent } from '../delete-row/delete-row.component';
import { RowTable } from 'src/app/shared/domain/interfaces/row-table';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-disable-row',
  templateUrl: './disable-row.component.html',
  styleUrls: ['./disable-row.component.scss']
})
export class DisableRowComponent {

  disableForm: FormGroup;

  constructor(private tableRepository: TableRepositoryService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public fb : FormBuilder,
    public dialogActions: MatDialogRef<DeleteRowComponent>,

    @Inject(MAT_DIALOG_DATA) public rowToDisable: RowTable,
  ) {

    this.disableForm = this.fb.group({

      id: new FormControl(this.rowToDisable.id,Validators.required),
      origin: new FormControl(this.rowToDisable.origin,Validators.required),
      note: new FormControl(this.rowToDisable.note,Validators.required),
      max_questions: new FormControl(this.rowToDisable.max_questions,Validators.required),
      budget_limit: new FormControl(this.rowToDisable.budget_limit,Validators.required),
      budget_block: new FormControl(this.rowToDisable.budget_block,Validators.required),
      disabled: new FormControl(this.rowToDisable.disabled,Validators.required),

    });


  }
  public disableRow() {
    console.log("d", this.disableForm.value.disabled)
     this.tableRepository.disableRow(this.disableForm.value).subscribe(() => {
      const message = this.disableForm.value.disabled ? 'Dominio desactivado' : 'Dominio activado';
      this.snackBar.open(message, undefined, { duration: 1000 });
    });
    this.dialogActions.close();
    window.location.reload();
  }

  closeDialog(): void {
    this.dialogActions.close(false);
  }
  actualizarValor(value: boolean) {
    this.disableForm.patchValue({
      disabled: value
    });
    this.disableRow();
  }

}
