<form [formGroup]="addForm" (ngSubmit)="onSubmit()">
  <div class="container-update">
    <div class="add-row"><h3>Añadir dominio</h3></div>

    <div class="sides"></div>
    <div class="item">
      <mat-form-field>
        <h5>Nombre</h5>
        <input
          matInput
          placeholder="Nombre"
          class="form-control"
          formControlName="note"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
        <h5>Dominio</h5>
        <input
          matInput
          placeholder="Dominio"
          class="form-control"
          formControlName="origin"
        />
      </mat-form-field>
    </div>

    <div class="item">
      <mat-form-field>
        <h5>Max. Preguntas</h5>
        <input
          matInput
          type="number"
          placeholder="Max. Preguntas"
          class="form-control"
          formControlName="max_questions"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
        <h5>Budget_limit</h5>
        <input
          matInput
          type="number"
          placeholder="Budget limit"
          class="form-control"
          formControlName="budget_limit"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
      <mat-select placeholder="Budget block" formControlName="budget_block">
          <mat-option value="true">Sí</mat-option>
          <mat-option value="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    <div class="item">
    <mat-form-field>
      <mat-select placeholder="Desactivado" formControlName="disabled">
        <mat-option value="true">Sí</mat-option>
        <mat-option value="false">No</mat-option>
      </mat-select>
    </mat-form-field>
    </div>
    <div class="add-button">
      <button type="submit" mat-raised-button color="primary">Añadir</button>
    </div>
  </div>
</form>
