
<div *ngIf="!rowToDisable.disabled;else other_disabled">
  <h2>¿Estás seguro de desactivar el dominio? <p>{{ rowToDisable.note }}</p></h2>
  <div mat-dialog-actions>
    <form [formGroup]="disableForm">
      <button mat-button (click)="closeDialog()">No</button>
      <button mat-button (click)="actualizarValor(true)">Sí</button>

    </form>
  </div>
</div>



<ng-template #other_disabled><h2>¿Estás seguro de activar el dominio? <p>{{ rowToDisable.note }}</p></h2>
  <div mat-dialog-actions>
    <form [formGroup]="disableForm">
      <button mat-button (click)="closeDialog()">No</button>
      <button mat-button (click)="actualizarValor(false)">Sí</button>

    </form>
  </div>
</ng-template>



