import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './modules/components/login/login.component';
import { TableComponent } from './modules/components/table/table.component';
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from "@angular/material/dialog";
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { HeaderComponent } from './shared/components/header/header.component';
import { FilterComponent } from './shared/components/filter/filter.component';
import { MainLayoutComponent } from './modules/components/main-layout/main-layout.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule} from '@angular/material/icon';
import { RowTableComponent } from './modules/components/row-table/row-table.component';
import { DeleteRowComponent } from './modules/components/delete-row/delete-row.component';
import { UpdateRowComponent } from './modules/components/update-row/update-row.component';
import { DetailsRowComponent } from './modules/components/details-row/details-row.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DisableRowComponent } from './modules/components/disable-row/disable-row.component';
import { AddRowComponent } from './modules/components/add-row/add-row.component';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TableComponent,
    HeaderComponent,
    FilterComponent,
    MainLayoutComponent,
    SidebarComponent,
    RowTableComponent,
    DeleteRowComponent,
    UpdateRowComponent,
    DetailsRowComponent,
    DisableRowComponent,
    AddRowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatFormFieldModule,
    MatButtonModule,
    HttpClientModule,
    MatInputModule,
    MatTableModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatSnackBarModule,
    MatToolbarModule


  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
