<mat-grid-list cols="10" >

  <mat-grid-tile [colspan] = "10" >
    <div class="header-container">
      <app-header></app-header>
    </div>
  </mat-grid-tile>

</mat-grid-list>

<mat-grid-list cols="10" rowHeight="100%" gutterSize="0px">

  <!-- <mat-grid-tile class="app-sidebar" [colspan] = "1" >
    <div class="sidebar-container">
      <app-sidebar></app-sidebar>
    </div>
  </mat-grid-tile> -->
  <mat-grid-tile [colspan] = "10" >
    <div class="table-container">
      <app-table></app-table>
    </div>
  </mat-grid-tile>
</mat-grid-list>
