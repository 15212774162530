<mat-form-field>
  <mat-label>Buscar</mat-label>
  <input matInput  #input (keyup)="applyFilter($event)">
</mat-form-field>
<button
      mat-fab
      color="warn"
      class="search-button"
    >
      <mat-icon>search</mat-icon>
</button>
