import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{


  loginForm : FormGroup;
  errors: any = null;

  constructor( public router: Router, public formBuilder: FormBuilder, public authService: AuthService){
      this.loginForm = this.formBuilder.group({
        username: '',
        password: ''
      })
  }
  @Input() error: string | null | undefined;

  @Output() submitEM = new EventEmitter();

  ngOnInit(): void {

  }


  onSubmit() {

    this.authService.signin(this.loginForm.value).subscribe(
      (tokenValue) => {

        localStorage.setItem('currentUser', tokenValue.token);

       },
      (error) => {
        this.errors = error.error;
        this.loginForm.reset();

      },
      () => {
        this.loginForm.reset();
        this.router.navigate(['table']);
      }
    );
  }

}
