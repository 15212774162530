<form [formGroup]="updateForm" (ngSubmit)="onSubmit()">
  <div class="container-update">
    <mat-toolbar role="toolbar" class="task-header">
      <div class="update-row"><h3>Actualizar dominio</h3></div>
      <span class="fx-spacer"></span>
      <button mat-icon-button (click)="closeDialog()">
          <mat-icon mat-list-icon>close</mat-icon>
      </button>
  </mat-toolbar>
    <div class="item">
      <mat-form-field>
        <h5>Nombre</h5>
        <input
          matInput
          placeholder="Nombre"
          class="form-control"
          formControlName="note"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
        <h5>Dominio</h5>
        <input
          matInput
          placeholder="Dominio"
          class="form-control"
          formControlName="origin"
        />
      </mat-form-field>
    </div>

    <div class="item">
      <mat-form-field>
        <h5>Max. Preguntas</h5>
        <input
          matInput
          placeholder="Max. Preguntas"
          class="form-control"
          formControlName="max_questions"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
        <h5>Budget_limit</h5>
        <input
          matInput
          placeholder="Budget limit"
          class="form-control"
          formControlName="budget_limit"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
        <h5>Budget_block</h5>
        <input
          matInput
          placeholder="Budget block"
          class="form-control"
          formControlName="budget_block"
        />
      </mat-form-field>
    </div>
    <div class="item">
      <mat-form-field>
        <h5>Nº de preguntas</h5>
        <input
          matInput
          placeholder="Nº de preguntas"
          class="form-control"
          formControlName="questions_made"
        />
      </mat-form-field>
    </div>


    <div class="update-button">
      <button type="submit" mat-raised-button color="primary">Actualizar</button>
    </div>
  </div>
</form>
