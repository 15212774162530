import { Component } from '@angular/core';
import { ItemSidebar } from '../../domain/interfaces/item-sidebar';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  rowsSidebar: ItemSidebar[] = [
    {iconSRC: 'language', title:'Dominios'},
    {iconSRC: 'people', title:'Usuarios'},


  ];

}
