import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TableRepositoryService } from 'src/app/shared/services/repository/table-repository.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-update-row',
  templateUrl: './update-row.component.html',
  styleUrls: ['./update-row.component.scss']
})
export class UpdateRowComponent {

  updateForm: FormGroup;

  constructor(private tableRepository : TableRepositoryService, public fb: FormBuilder, private snackBar: MatSnackBar,
    private router: Router,    @Inject(MAT_DIALOG_DATA) public rowToUpdate: any,     public dialogActions: MatDialogRef<UpdateRowComponent>,


    ) {


      this.updateForm = this.fb.group({


        id: new FormControl(this.rowToUpdate.id,Validators.required),
        origin: new FormControl(this.rowToUpdate.origin,Validators.required),
        note: new FormControl(this.rowToUpdate.note,Validators.required),
        max_questions: new FormControl(this.rowToUpdate.max_questions,Validators.required),
        budget_limit: new FormControl(this.rowToUpdate.budget_limit,Validators.required),
        budget_block: new FormControl(this.rowToUpdate.budget_block,Validators.required),
        disabled: new FormControl(this.rowToUpdate.disabled,Validators.required),
        questions_made: new FormControl(this.rowToUpdate.questions_made,Validators.required),
        total_money_spent: new FormControl(this.rowToUpdate.total_money_spent,Validators.required),


      });


    }


    closeDialog(): void {
      this.dialogActions.close(false);
    }


    onSubmit(){

       this.tableRepository.updateRow(this.updateForm.value).subscribe(() => {
         this.snackBar.open('Dominio actualizado', undefined, {
           duration: 1500,
         });
         window.location.reload();
        })

    }
}
