import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';
import { RowTable } from '../../domain/interfaces/row-table';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableRepositoryService {


  private API_URL= environment.API_URL;
  private options = {
  headers: new HttpHeaders({
    'Authorization': `Token ${localStorage.getItem('currentUser')}`
  }) // TODO: This is a bad practice. It should be a wrapper
};
  constructor(private http: HttpClient) {}

  getAllRows(): Observable<any> {
    return this.http.get<any>(this.API_URL + 'domains/', this.options );
  }

  addDomain(domain : RowTable) : Observable<RowTable>{
    return this.http.post<any>(this.API_URL + 'domains/',domain, this.options);
  }

  deleteRow(id : Number): Observable<void>{
    return this.http.delete<any>(`${this.API_URL}domains/${id}/`, this.options);

  }

  updateRow(form : RowTable) : Observable<void>{
    return this.http.put<any>(`${this.API_URL}domains/${form.id}/`,form, this.options);

  }

   disableRow(form : RowTable): Observable<any>{
     return this.http.patch<any>(`${this.API_URL}domains/${form.id}/`,form, this.options);

   }
}
