import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TableRepositoryService } from 'src/app/shared/services/repository/table-repository.service';

@Component({
  selector: 'app-add-row',
  templateUrl: './add-row.component.html',
  styleUrls: ['./add-row.component.scss']
})
export class AddRowComponent {
  addForm: FormGroup;
  data: any[] = [];

  constructor(private tableRepository : TableRepositoryService, public fb: FormBuilder, private snackBar: MatSnackBar,  @Inject(MAT_DIALOG_DATA) public rowToUpdate: any) {


      this.addForm = this.fb.group({


        origin: new FormControl(this.rowToUpdate.origin,Validators.required),
        note: new FormControl(this.rowToUpdate.note,Validators.required),
        max_questions: new FormControl(this.rowToUpdate.max_questions,Validators.required),
        budget_limit: new FormControl(this.rowToUpdate.budget_limit,Validators.required),
        budget_block: new FormControl(this.rowToUpdate.budget_block,Validators.required),
        disabled: new FormControl(this.rowToUpdate.disabled,Validators.required),

      });


    }



    onSubmit(){

       this.tableRepository.addDomain(this.addForm.value).subscribe(() => {
         this.snackBar.open('Dominio añadido', undefined, {
           duration: 1500,
         });
         window.location.reload();
        })

    }
}
