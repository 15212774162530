import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RowTable } from 'src/app/shared/domain/interfaces/row-table';
import { TableRepositoryService } from 'src/app/shared/services/repository/table-repository.service';
import { DeleteRowComponent } from '../delete-row/delete-row.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpdateRowComponent } from '../update-row/update-row.component';
import { DisableRowComponent } from '../disable-row/disable-row.component';

@Component({
  selector: 'app-row-table',
  templateUrl: './row-table.component.html',
  styleUrls: ['./row-table.component.scss']
})
export class RowTableComponent {

  public rows : RowTableComponent[] = [];


constructor(private tableRepository : TableRepositoryService,private dialog: MatDialog, private router : Router) {}

  @Input()
  rowTable: RowTable = {} as RowTable;

  getAllRows(){

    return this.tableRepository.getAllRows().subscribe((rows:RowTableComponent[] ) => this.rows = rows);

  }


  deleteRow(rowToDelete: RowTable) {
    let dialogRef = this.dialog.open(DeleteRowComponent, {
      width: '500px',
      height: '150px',
      data: rowToDelete,
    });
    dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    });
  }

  updateRow(rowToUpdate: RowTable) {
    let dialogRef = this.dialog.open(UpdateRowComponent, {
      width: '90%',
      height: '90%',
      data: rowToUpdate,
    });

  }

  disableRow(rowToDisable: RowTable) {
    let dialogRef = this.dialog.open(DisableRowComponent, {
      width: '500px',
      height: '150px',
      data: rowToDisable,
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }
}
