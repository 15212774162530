import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../domain/interfaces/user';
import { environment } from 'src/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private API_URL= environment.API_URL;

  constructor(private http: HttpClient) {

  }


  signin(user: User): Observable<any> {
    return this.http.post<any>(this.API_URL + 'auth/token/', user);
  }

  loggedIn() {
    return !!localStorage.getItem('currentUser');
   }
}
