<div class="header-container">
  <img src="../../../../assets/images/vega_dni.png">
  <h2>PANEL ADMINISTRACIÓN VEGA</h2>
  <button
      mat-fab
      color="warn"
      class="delete-button"
      (click)="closeSession()"
    >
      <mat-icon>power_settings_new</mat-icon>
    </button>
</div>
